import React, { Fragment, useEffect, useState } from 'react';
import styles from './AddOrEditCampaign.module.css';
import { NotificationManager } from 'react-notifications';
import { selectUsersList } from '../../../../redux/tasks/tasksSlice';
import { useSelector } from 'react-redux';
import {
  USER_ROLES,
  tier2NavigationModeOptions,
  ASSISTED_WORKS,
  DEFAULT_LABELERS_NUMBER,
  isNullOrWhitespace,
} from '../SharedLogic';
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import CampaignSettings from './Steps/CampaignSettings';
import SelectUsers from './Steps/SelectUsers';
import SelectFindings from './Steps/SelectFindings';
import SelectBatches from './Steps/SelectBatches';
import EditSelectBatches from './Steps/EditSelectBatches';
import TabForm from '../TabForm';
import { SubmitHiddenButton } from '../../Components';
import { getUserName } from '../../../TasksList/TasksList.logic';
import { HourglassProgress } from '../../../../components';
import classNames from 'classnames';
import EditCampaignSettings from './Steps/EditCampaignSettings';
import EditSelectUsers from './Steps/EditSelectUsers';
import EditSelectFindings from './Steps/EditSelectFindings';

const steps = [
  'Select Campaign Settings',
  'Select Batches',
  'Select Findings',
  'Select Users',
];

const ASC_ORDER = 'asc';
const USER_ID_CELL = 'UserId';

const AddOrEditCampaign = (props) => {
  const {
    tasksListLogic,
    campaignsList,
    // findingsList,
    // batchesList,
    preSelectedCampaignBatches,
    setPreSelectedCampaignBatches,
    selectedUsersAndRoles,
    setSelectedUsersAndRoles,
    campaignName,
    setCampaignName,
    campaignOwner,
    setCampaignOwner,
    campaignGroup,
    setCampaignGroup,
    // campaignsGroups,
    setCampaignsGroups,
    selectedBatches,
    setSelectedBatches,
    selectedFindings,
    setSelectedFindings,
    isHidden,
    setIsHidden,
    numberOfTier1Labelers,
    setNumberOfTier1Labelers,
    tier2NavigationMode,
    setTier2NavigationMode,
    assistedWork,
    setAssistedWork,
    aiAsAdditionalTier1,
    setAiAsAdditionalTier1,
    disableAutoCorrection,
    setDisableAutoCorrection,
    campaignGuidance,
    setCampaignGuidance,
    activeStep,
    setActiveStep,
    setPreSelectedFindings,
    setInactivatedFindings,
    // usersSortOrder,
    setUsersSortOrder,
    // groupsSortOrder,
    setGroupsSortOrder,
    // sortBy,
    setSortBy,
    // selectedFilteredGroups,
    setSelectedFilteredGroups,
    resetRef,
    fetchData,
  } = props;
  const usersList = useSelector(selectUsersList);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const isLastStep = activeStep === steps.length - 1;

  const getRolesArray = () => {
    return USER_ROLES.map((role) => ({
      label: role,
      checked: false,
    }));
  };

  useEffect(() => {
    setSelectedUsersAndRoles((prev) =>
      usersList.map((user) => {
        return {
          ...user,
          roles:
            prev.find((u) => u.UserId === user.UserId)?.roles ??
            getRolesArray(),
        };
      })
    );
  }, [setSelectedUsersAndRoles, usersList]);

  useEffect(() => {
    setCampaignsGroups(
      [...new Set(campaignsList.map((item) => item?.group))].sort()
    );
  }, [campaignsList, setCampaignsGroups]);

  const getSelectedRoles = (roles) => {
    const selectedRoles = [];
    roles.forEach((role) => {
      if (role.checked) {
        selectedRoles.push(role.label);
      }
    });
    return selectedRoles;
  };

  const getSelectedUsersAndRoles = () => {
    const selectedUsers = {};
    selectedUsersAndRoles.forEach((user) => {
      const selectedRoles = getSelectedRoles(user.roles);
      if (selectedRoles.length !== 0) {
        selectedUsers[user.UserId] = selectedRoles;
      }
    });
    return selectedUsers;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitInProgress(true);

    if (!props.isEdit && !selectedFindings.length) {
      NotificationManager.error(
        `Can't add campaign without findings.`,
        'Warning'
      );
      return;
    }

    const selectedUsers = getSelectedUsersAndRoles();

    const newCampaign = {
      isEdit: props.isEdit,
      campaignId: campaignsList.find((x) => x.name === campaignName)?.id,
      campaignName,
      campaignOwner,
      campaignGroup,
      usersAndRoles: selectedUsers,
      batches: selectedBatches,
      hidden: isHidden,
      numberOfTier1Labelers,
      tier2NavigationMode,
      assistedWork,
      aiAsAdditionalTier1,
      disableAutoCorrection,
      findings: selectedFindings,
      campaignGuidance,
    };

    try {
      await tasksListLogic.postAddCampaign(newCampaign);
      await resetParameters();
      if (props.isEdit) {
        NotificationManager.success('Campaign Edited successfully!', 'Success');
      } else {
        NotificationManager.success(
          'Campaign Created successfully!',
          'Success'
        );
      }
    } catch (error) {
      NotificationManager.error(
        `An error occurred while adding the campaign: \n  ${error}. \n  Please try again later.`,
        'Warning'
      );
    }
    setSubmitInProgress(false);
  };

  const resetParameters = async () => {
    setSelectedUsersAndRoles([]);
    setCampaignName('');
    setCampaignOwner(getUserName());
    setCampaignGroup('');
    setSelectedFindings([]);
    setIsHidden(false);
    setSelectedBatches([]);
    setNumberOfTier1Labelers(DEFAULT_LABELERS_NUMBER);
    setTier2NavigationMode(tier2NavigationModeOptions[0]);
    setAssistedWork(ASSISTED_WORKS[0]);
    setAiAsAdditionalTier1(false);
    setDisableAutoCorrection(false);
    setCampaignGuidance('');
    setActiveStep(0);
    setUsersSortOrder(ASC_ORDER);
    setGroupsSortOrder(ASC_ORDER);
    setSortBy(USER_ID_CELL);
    setSelectedFilteredGroups([]);
    if (props.isEdit) {
      setPreSelectedCampaignBatches([]);
      setInactivatedFindings([]);
      setPreSelectedFindings([]);
    }
    await fetchData();
  };

  const isAtLeastOneUserSelected = () => {
    if (props.isEdit) return true;
    let roleSelected = false;
    selectedUsersAndRoles.forEach((user) => {
      user.roles.forEach((role) => {
        if (role.checked) {
          roleSelected = true;
          return;
        }
      });
    });
    return roleSelected;
  };

  const isAtLeastOneFindingSelected = () => {
    return selectedFindings.length !== 0;
  };

  const isAtLeastOneBatchSelected = () => {
    return props.isEdit
      ? selectedBatches.length !== 0 || preSelectedCampaignBatches.length !== 0
      : selectedBatches.length !== 0;
  };

  const validateCampaignSettingsTab = () => {
    const validateCampaignName = () => {
      return !isNullOrWhitespace(campaignName);
    };
    const validateCampaignOwner = () => {
      return !isNullOrWhitespace(campaignOwner);
    };

    const isValidate = validateCampaignName() && validateCampaignOwner();
    let msg = null;
    if (!isValidate) {
      if (!validateCampaignName()) {
        msg = 'Fill Campaign Name';
      } else if (!validateCampaignOwner()) {
        msg = 'Fill Campaign Owner';
      }
    }

    return { isValidate, msg };
  };

  const validateBatchesTab = () => {
    const isValidate = isAtLeastOneBatchSelected();
    const msg = 'Select At Least One Batch';
    return { isValidate, msg };
  };

  const validateFindingsTab = () => {
    const isValidate = isAtLeastOneFindingSelected();
    const msg = 'Select At Least One Finding';
    return { isValidate, msg };
  };

  const validateUsersTab = () => {
    const isValidate = isAtLeastOneUserSelected();
    const msg = 'Select At Least One User';
    return { isValidate, msg };
  };

  const validateStep = (index) => {
    const ErrorStep = ({ msg }) => (
      <Typography variant="caption" color="error">
        {msg}
      </Typography>
    );

    const validators = [
      validateCampaignSettingsTab,
      validateBatchesTab,
      validateFindingsTab,
      validateUsersTab,
    ];
    if (index === steps.length) {
      return validators.every((v) => v().isValidate);
    }

    if (activeStep > index) {
      const { isValidate, msg } = validators[index]();
      if (!isValidate) {
        return <ErrorStep msg={msg} />;
      }
    }

    return null;
  };

  const validateActiveStep = (activeStep) => {
    if (props.isEdit && activeStep === 0) {
      const { isValidate } = validateCampaignSettingsTab();
      return !isValidate;
    }
  };
  const handleNext = (e) => {
    e.stopPropagation();
    setActiveStep((prevActiveStep) => ++prevActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => --prevActiveStep);
  };

  const handleChangeCampaignGuidance = (value) => {
    setCampaignGuidance(value);
  };

  const stepsComponents = [
    <CampaignSettings
      {...props}
      handleChangeCampaignGuidance={handleChangeCampaignGuidance}
    />,
    <SelectBatches {...props} />,
    <SelectFindings {...props} />,
    <SelectUsers {...props} />,
  ];
  const editStepsComponents = [
    <EditCampaignSettings
      {...props}
      handleChangeCampaignGuidance={handleChangeCampaignGuidance}
      fetchData={fetchData}
    />,
    <EditSelectBatches {...props} />,
    <EditSelectFindings {...props} />,
    <EditSelectUsers {...props} />,
  ];
  return (
    <TabForm className={styles.tabForm} onSubmit={handleSubmit}>
      <Stepper className={styles.stepper} activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          const stepValidation = validateStep(index);
          if (stepValidation) {
            labelProps.optional = stepValidation;
            labelProps.error = true;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Fragment>
        <TabForm className={styles.stepForm}>
          {submitInProgress ? (
            <div className={styles.hourglassProgress}>
              <HourglassProgress />
            </div>
          ) : props.isEdit ? (
            editStepsComponents[activeStep]
          ) : (
            stepsComponents[activeStep]
          )}
        </TabForm>
        <Box className={styles.buttonsContainer}>
          <SubmitHiddenButton ref={resetRef} onClick={resetParameters} />
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
            className={styles.buttonBack}
          >
            Back
          </Button>
          <Box className={styles.buttonSpacer} variant="outlined" />
          <div
            className={classNames({
              [styles.hiddenBtn]: isLastStep,
              [styles.visibleBtn]: !isLastStep,
            })}
          >
            <Button
              onClick={handleNext}
              variant="outlined"
              disabled={validateActiveStep(activeStep)}
            >
              Next
            </Button>
          </div>
          <div
            className={classNames({
              [styles.hiddenBtn]: !isLastStep,
              [styles.visibleBtn]: isLastStep,
            })}
          >
            <Button
              type="submit"
              disabled={!validateStep(steps.length) || submitInProgress}
              variant="outlined"
            >
              Finish
            </Button>
          </div>
        </Box>
      </Fragment>
    </TabForm>
  );
};

export const AddCampaign = (props) => {
  return <AddOrEditCampaign {...props} />;
};

export const EditCampaign = (props) => {
  return <AddOrEditCampaign {...props} isEdit={true} />;
};
